import React from "react";
import styled from "@emotion/styled";
import {BottomPadding, C, TopPadding} from "../../ui/multiUseStyle";
import Container from "react-bootstrap/cjs/Container";
import Col from "react-bootstrap/cjs/Col";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/cjs/Row";
import Aurubis from "../../images/Aurubis.webp";
import NMBS from "../../images/NMBS.svg";
import EXXON from "../../images/EXXON.webp";
import ENGIE from "../../images/ENGIE.webp";
import ELIA from "../../images/elia.webp";
import EQUANS from "../../images/EQUANS.webp";
import Umicore from "../../images/Umicore.webp";
import Vaillant from "../../images/vaillant.webp";
import Safran from "../../images/safran.webp";
import CardGroup from "react-bootstrap/CardGroup";

function Partners() {
    return (
        <>
            <TopPadding/>
            <Container>
                <br/>
                <h1><C>P</C>artners</h1>
                <Row>
                    <CardGroup>
                        <Col md={12}>
                            <Partner target={"_blank"} href={"https://vaillant.be/vacatures/"}>
                                <StyledCard border="secondary" className={"mb-3"}>
                                    <CardHeader>
                                        <CardImg variant="top" src={Vaillant} alt={"Vaillant"}/>
                                    </CardHeader>
                                    <Card.Body>
                                        <Card.Title>Vaillant</Card.Title>
                                        <Card.Text>
                                            Al meer dan 150 jaar ontwikkelt Vaillant, Europese marktleider in verwarmingstechnologie,
                                            toekomstgerichte en efficiënte technologieën die beantwoorden aan de behoeftes op het vlak van comfort,
                                            verbruik en efficiëntie. Vaillant biedt kwalitatieve, duurzame en milieuvriendelijke oplossingen voor verwarming,
                                            koeling en warm water. Ontdek ons uitgebreid gamma condensatieketels, warmtepompen, zonneboilers, slimme thermostaten, …
                                        </Card.Text>
                                    </Card.Body>
                                </StyledCard>
                            </Partner>
                        </Col>

                        <Col md={6}>
                            <Partner target={"_blank"} href={"https://www.aurubis.com/olen/"}>
                                <StyledCard border="secondary" className={"mb-3"}>
                                    <CardHeader>
                                        <CardImg variant="top" src={Aurubis} alt={"AURUBIS"}/>
                                    </CardHeader>
                                    <Card.Body>
                                        <Card.Title>Aurubis</Card.Title>
                                        <Card.Text>
                                            Aurubis Olen, deel van de Aurubis Groep, is gespecialiseerd in de productie
                                            van koperanodes, koperkathodes, Aurubis rod, Aurubis foxrod en Aurubis Bars
                                            & Profiles.
                                            <br/><br/>
                                            Met hun koperproducten legt Aurubis de basis voor de huidige en toekomstige
                                            gigatrends: digitalisering, e-mobility, ...
                                            <br/><br/>
                                            Een sterk team van ruim 650 Olense collega's geeft elke dag het beste van
                                            zichzelf. Samen gaan ze voor een groene toekomst vol duurzame mogelijkheden.
                                        </Card.Text>
                                    </Card.Body>
                                </StyledCard>
                            </Partner>
                        </Col>
                        
                        <Col md={6}>
                            <Partner target={"_blank"} href={"https://www.elia.be/"}>
                                <StyledCard border="secondary" className={"mb-3"}>
                                    <CardHeader>
                                        <CardImg variant="top" src={ELIA} alt={"Elia"}/>
                                    </CardHeader>
                                    <Card.Body>
                                        <Card.Title>Elia</Card.Title>
                                        <Card.Text>
                                        Wij zijn Elia, de Belgische transmissienetbeheerder die de betrouwbaarheid en duurzaamheid van de elektriciteitsbevoorrading in ons land verzekert. 
                                        Studeer je binnenkort af? Ben je klaar om te shinen en te groeien naar ongekende hoogtes? 
                                        Elia is the place to be! Bij Elia bouw je mee aan de toekomst van het elektriciteitsnet in België 
                                        en krijg je als getalenteerde technieker, ingenieur, designer of data- en IT-profiel de kans om samen een impact te maken die de wereld verlicht.  <br/><br/>
                                        Drie redenen om voor een job bij Elia te kiezen: <br/>
                                        <ol>
                                            <li>
                                                Een job vol variatie in een werkomgeving waar impact en innovatie centraal staan.
                                            </li>
                                            <li>
                                                Focus op persoonlijke groei en welzijn.
                                            </li>
                                            <li>
                                                Een divers team van gepassioneerde collega’s staat klaar om jou te verwelkomen.
                                            </li>
                                        </ol>
                                        Wens je graag meer informatie? Contacteer ons zeker via <a href="mailto:campus@elia.be">campus@elia.be</a>
                                        </Card.Text>
                                    </Card.Body>
                                </StyledCard>
                            </Partner>
                        </Col>

                        <Col md={6}>
                            <Partner target={"_blank"} href={"https://www.engie.be/jobs"}>
                                <StyledCard border="secondary" className={"mb-3"}>
                                    <CardHeader>
                                        <CardImg variant="top" src={ENGIE} alt={"Engie"}/>
                                    </CardHeader>
                                    <Card.Body>
                                        <Card.Title>ENGIE</Card.Title>
                                        <Card.Text>
                                            ENGIE wil de rol van leider in de energietransitie opnemen. Als grootste
                                            groenestroomproducent van België investeert het massaal in lokale en
                                            grotendeels koolstofvrije energiecapaciteit.
                                            <br/><br/>
                                            ENGIE beheerst alle beroepen uit de energiesector: van energieproductie tot
                                            onderhoud van complexe installaties, van engineering tot het bouwen van
                                            nieuwe installaties, van onderzoek en innovatie tot het optimaliseren van
                                            ieders verbruik.
                                        </Card.Text>
                                    </Card.Body>
                                </StyledCard>
                            </Partner>
                        </Col>

                        <Col md={6}>
                            <Partner target={"_blank"} href={"https://jobs.equans.be/nl/"}>
                                <StyledCard border="secondary" className={"mb-3"}>
                                    <CardHeader>
                                        <CardImg variant="top" src={EQUANS} alt={"Equans"}/>
                                    </CardHeader>
                                    <Card.Body>
                                        <Card.Title>Equans</Card.Title>
                                        <Card.Text>
                                            Equans is de referentie voor multitechnische installaties en
                                            onderhoudsdiensten en maakt deel uit van de groep Bouygues. In ons bedrijf
                                            ontwerpen, installeren en leveren we op maat gemaakte oplossingen en
                                            diensten om de infrastructuur, uitrustingen, systemen en technische
                                            processen van onze klanten te verbeteren.
                                        </Card.Text>
                                    </Card.Body>
                                </StyledCard>
                            </Partner>
                        </Col>

                        <Col md={6}>
                            <Partner target={"_blank"} href={"https://www.exxonmobil.be/nl-be"}>
                                <StyledCard border="secondary" className={"mb-3"}>
                                    <CardHeader>
                                        <Card.Img variant="top" src={EXXON} alt={"EXXON"}/>
                                    </CardHeader>
                                    <Card.Body>
                                        <Card.Title>EXXON</Card.Title>
                                        <Card.Text>
                                            ExxonMobil is actief op alle continenten, dus ook op onze locaties in
                                            België. Wie in onze raffinaderij in de haven van Antwerpen komt werken,
                                            stapt in een rijk verleden ( > 130 jaar aanwezig) en een boeiende toekomst.
                                            Met investeringen van meer dan 2 miljard dollar de afgelopen 15 jaar zijn we
                                            één van de modernste raffinaderijen ter wereld.
                                        </Card.Text>
                                    </Card.Body>
                                </StyledCard>
                            </Partner>
                        </Col>

                        <Col md={6}>
                            <Partner target={"_blank"} href={"https://hr-rail.be/nl"}>
                                <StyledCard border="secondary" className={"mb-3"}>
                                    <CardHeader>
                                        <CardImg variant="top" src={NMBS} alt={"NMBS"}/>
                                    </CardHeader>
                                    <Card.Body>
                                        <Card.Title>NMBS</Card.Title>
                                        <Card.Text>
                                            Reizigers veilig en stipt van A naar B brengen. Dat kan alleen met treinen
                                            en stations in topconditie. Wie in techniek werkt, speelt daarin een
                                            sleutelrol.
                                            <br/><br/>
                                            Mechanica, elektriciteit, elektromechanica, elektronica, HVAC, … Bij NMBS
                                            vind je vast een job die past bij jouw passie voor techniek. Vol
                                            afwisseling, vrijheid en groeikansen.
                                        </Card.Text>
                                    </Card.Body>
                                </StyledCard>
                            </Partner>
                        </Col>

                        
                        <Col md={6}>
                            <Partner target={"_blank"} href={"www.safran-aircraft-engines.com"}>
                                <StyledCard border="secondary" className={"mb-3"}>
                                    <CardHeader>
                                        <Card.Img variant="top" src={Safran} alt={"Safran"}/>
                                    </CardHeader>
                                    <Card.Body>
                                        <Card.Title>Safran Aircraft Engine Services Brussels</Card.Title>
                                        <Card.Text>
                                        Safran Aircraft Engine Services Brussels, een dochteronderneming van Safran Aircraft Engines, 
                                        is gevestigd op Brussels Airport en gespecialiseerd in het onderhoud, 
                                        de inspectie en reparatie van LEAP-straalmotoren voor Airbus A320NEO en Boeing B737MAX. 
                                        Safran is een internationale technologiegroep actief in luchtvaart, ruimtevaart en defensie. 
                                        Door de groei in LEAP-motoren zijn we op zoek naar Mechanics, Technicians & Engineers om ons team te versterken, 
                                        met tal van carrièremogelijkheden binnen het bedrijf. 
                                        Ben je op zoek naar ambitieuze carrièremogelijkheden in een internationale omgeving en heb je een passie voor luchtvaart, 
                                        dan mag je je cv sturen naar <a href="mailto:recruitment-saesb@safrangroup.com">recruitment-saesb@safrangroup.com</a> 
                                        <br/>
                                        We kijken er naar uit!
                                        </Card.Text>
                                    </Card.Body>
                                </StyledCard>
                            </Partner>
                        </Col>

                        <Col md={6}>
                            <Partner target={"_blank"} href={"https://www.umicore.be/nl/jobs/"}>
                                <StyledCard border="secondary" className={"mb-3"}>
                                    <CardHeader>
                                        <CardImg variant="top" src={Umicore} alt={"Umicore"}/>
                                    </CardHeader>
                                    <Card.Body>
                                        <Card.Title>Umicore</Card.Title>
                                        <Card.Text>
                                            Umicore is een toonaangevend bedrijf in circulaire materiaaltechnologie met
                                            een uitgebreide expertise op het gebied van materiaalwetenschappen, chemie
                                            en metallurgie. Onze allesoverheersende doelstelling van duurzame
                                            waardecreatie is gebaseerd op de ambitie om materialen te ontwikkelen, te
                                            produceren en te recycleren op een manier die beantwoordt aan onze missie:
                                            "materials for a better life".
                                        </Card.Text>
                                    </Card.Body>
                                </StyledCard>
                            </Partner>
                        </Col>
                    </CardGroup>
                </Row>
                <br/>
                <Row>
                    <Center>
                        <h6>
                            Uw bedrijf hier? Neem dan contact met ons op!
                        </h6>
                        <ContactRef
                            href={"mailto:info@mereta.be?subject=Contact"}><ContactButton1>CONTACT</ContactButton1></ContactRef>
                    </Center>
                </Row>
                <br/>
            </Container>
            <BottomPadding/>
        </>
    )
}

const Partner = styled.a`
  text-decoration: none;
  color: black;

  &:hover {
    text-decoration: none;
    color: black;
  }
`;

const StyledCard = styled(Card)`
  transition: all 0.4s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

const CardHeader = styled(Card.Header)`
  height: 200px;
`;

const CardImg = styled(Card.Img)`
  height: 180px;
  width: auto;
  display: block;
  margin: auto;
`;

const Center = styled(Col)`
  text-align: center;
  align-items: center;
`;

const ContactButton1 = styled.button`
  width: 120px;
  background-color: #034073;
  color: white;
  border: none;
  padding: 0.3em;
  text-decoration: none !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.25em;
  opacity: 0.8;
  margin-bottom: 2vh;
  transition: 0.4s;

  &:hover {
    background-color: #021b30;
  }
`;

const ContactRef = styled.a`
  text-decoration: none !important;
  color: white;

  &:hover {
    color: white;
  }

  @media (max-width: 992px) {
    margin-left: 3vw;
  }
`;


export default Partners;
